<template>
    <component :is="form === undefined ? 'div' : 'b-card'">
        <template>
            <div v-if="hasPermission">
                <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
                    <!-- Form -->
                    <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
                        <b-row>
                            <b-col cols="12">
                                <!-- media -->
                                <b-media no-body>
                                    <b-media-body class="mt-75 ml-75">
                                        <b-row>
                                            <b-col cols="12" sm="4">
                                                <!-- upload button -->

                                                <image-cropper v-model="fileForm" label="manager image" :stencil-props="{ aspectRatio: 1280 / 720, checkImageOrigin: false }" :url.sync="form.thumbnail" />
                                                <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
                                                <!--/ upload button -->
                                            </b-col>
                                        </b-row>
                                    </b-media-body>
                                </b-media>
                                <!--/ media -->
                            </b-col>
                        </b-row>
                        <b-row class="mt-3">
                            <!-- Name -->
                            <b-col cols="12" sm="6" md="3">
                                <validation-provider #default="validationContext" name="name" rules="required">
                                    <b-form-group label="Name" label-for="name">
                                        <b-form-input id="name" v-model="form.name" :state="getValidationState(validationContext)" trim autocomplete="off" />

                                        <b-form-invalid-feedback>
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <!-- Arabic Name -->
                            <b-col cols="12" sm="6" md="3">
                                <validation-provider name="arabicName">
                                    <b-form-group label="Arabic Name" label-for="arabic-name">
                                        <b-form-input id="arabic-name" v-model="form.name_ar" trim />
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <!-- Genre -->
                            <b-col cols="12" sm="6" md="3">
                                <validation-provider #default="validationContext" name="genre" rules="required">
                                    <b-form-group label="Genre" label-for="genre">
                                        <v-select v-model="form.genre_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(val) => val.id" :clearable="false" :options="genres" label="name_ar" input-id="genre" @search="filterGenres" />
                                        <b-form-invalid-feedback>
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <!-- Artist -->
                            <b-col cols="12" sm="6" md="3">
                                <validation-provider #default="validationContext" name="artist" rules="required">
                                    <b-form-group label="Artist" label-for="artist">
                                        <v-select v-model="form.artist_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(val) => val.id" :options="artists" label="name" :clearable="false" input-id="artist" @search="filterArtist" />
                                        <b-form-invalid-feedback>
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <!-- Length -->
                            <b-col cols="12" sm="6" md="3">
                                <validation-provider #default="validationContext" name="length" rules="required">
                                    <b-form-group label="Length" label-for="length">
                                        <b-form-input id="length" v-model="form.length" type="number" :state="getValidationState(validationContext)" trim />

                                        <b-form-invalid-feedback>
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col cols="12" sm="6" md="3">
                                <validation-provider #default="validationContext" name="Fake Played" rules="required">
                                    <b-form-group label="Promoted Play" label-for="fake-played" :state="getValidationState(validationContext)">
                                        <b-form-input id="fake-played" v-model="form.fake_played" type="number" :state="getValidationState(validationContext)" trim />
                                        <span class="text-danger text-nowrap">{{ fakeValError }}</span>
                                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <!-- Played Count -->
                            <b-col cols="12" sm="6" md="3">
                                <validation-provider #default="validationContext" name="PlayedCount" rules="required">
                                    <b-form-group label="PlayedCount" label-for="played-count" :state="getValidationState(validationContext)">
                                        <b-form-input id="played-count" v-model="form.panel_played_count" disabled type="number" :state="getValidationState(validationContext)" trim />
                                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col cols="12" sm="6" md="3">
                                <validation-provider #default="validationContext" name="PlayedCount">
                                    <b-form-group label="Total Played" label-for="total-played" :state="getValidationState(validationContext)">
                                        <b-form-input id="total-played" v-model="form.total_played" disabled type="number" :state="getValidationState(validationContext)" trim />
                                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <!-- Link -->
                            <b-col cols="12" sm="6">
                                <validation-provider #default="validationContext" name="link" rules="required|url">
                                    <b-form-group label="Link" label-for="link" :state="getValidationState(validationContext)">
                                        <b-form-input id="link" v-model="form.link" :state="getValidationState(validationContext)" trim />
                                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <!-- Share Link -->
                            <b-col cols="12" sm="12">
                                <validation-provider #default="validationContext" name="share_link" rules="url">
                                    <b-form-group label="Share link" label-for="share_link" :state="getValidationState(validationContext)">
                                        <b-form-input id="share_link" v-model="form.share_link" :state="getValidationState(validationContext)" trim />
                                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <!-- Description -->
                            <b-col cols="12">
                                <validation-provider #default="validationContext" name="Description" rules="required">
                                    <b-form-group label="description" label-for="description" :state="getValidationState(validationContext)">
                                        <b-form-textarea v-model="form.description" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(val) => val.value" input-id="description" rows="4" />
                                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <!-- Lyric -->
                            <b-col cols="12">
                                <validation-provider #default="validationContext" name="Lyric" rules="required">
                                    <b-form-group label="Lyric" label-for="lyric" :state="getValidationState(validationContext)">
                                        <b-form-textarea v-model="form.lyric" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(val) => val.value" input-id="lyric" rows="4" />
                                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                            {{ validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <!-- Exclusive -->
                            <b-col cols="12">
                                <validation-provider name="Exclusive">
                                    <b-form-group label="Exclusive" label-for="exclusive">
                                        <b-form-checkbox v-model="form.exclusive" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" input-id="exclusive" name="exclusive" switch inline> </b-form-checkbox>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <!-- Form Actions -->
                            <b-col cols="12">
                                <div class="d-flex mt-2">
                                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" class="mr-2" type="submit">
                                        {{ videoId ? "update" : "add" }}
                                    </b-button>
                                    <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-danger"> reset </b-button>
                                </div>
                            </b-col>
                        </b-row>
                    </b-form>
                </validation-observer>
            </div>
            <div v-else class="text-center">
                <h1>⛔ You are not Allowed to be here ⛔</h1>
            </div>
        </template>
    </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BFormGroup, BForm, BRow, BCol, BFormInput, BMedia, BFormFile, BImg, BFormTextarea, BCardText, BButton, BMediaBody, BMediaAside, BFormInvalidFeedback, BFormCheckbox } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, url } from "@validations";
import { ref, onUnmounted, onMounted, watch } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { avatarText } from "@core/utils/filter";
// repository
import FileRepository from "@/abstraction/repository/fileRepository";
import ArtistRepository from "@/abstraction/repository/artistRepository";
import GenreRepository from "@/abstraction/repository/genresRepository";
import VideoRepository from "@/abstraction/repository/videoRepository";
// random token
import { makeid } from "@/utils/math";
import debouncedFunction from "@/utils/filter/autocompleteFilter.js";
import useUsersList from "../user/useUsersList";
import userStoreModule from "../user/userStoreModule";
import Switch from "@/views/forms/form-element/form-switch/Switch";
import SwitchBasic from "@/views/forms/form-element/form-switch/SwitchBasic";

const artistRepository = new ArtistRepository();
const genreRepository = new GenreRepository();
const videoRepository = new VideoRepository();
const RANDOM_TOKEN = makeid(50);

export default {
    components: {
        BFormCheckbox,
        BTab,
        BFormInput,
        BFormInvalidFeedback,
        BFormGroup,
        BForm,
        BFormTextarea,
        BFormFile,
        BImg,
        BMedia,
        BTabs,
        BCard,
        BAlert,
        BRow,
        BCol,
        vSelect,
        BButton,
        BCardText,
        BMediaAside,
        BMediaBody,
        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    data: () => ({
        required,
        url,
        genres: [],
        artists: [],
        isLoading: false,
    }),
    setup() {
        const blankForm = {
            name: "",
            name_ar: "",
            description: "",
            lyric: "",
            exclusive: true,
            played_count: null,
            genre_id: "",
            artist_id: "",
            length: null,
            link: "",
            share_link: "",
            file_batch_id: RANDOM_TOKEN,
            thumbnail: "https://via.placeholder.com/1280x720",
        };
        const videoId = ref(router.currentRoute.params.id);
        const fileForm = ref({
            batch_id: RANDOM_TOKEN,
            collection_name: "main_image",
            model_name: "video",
            crop: {},
        });
        const roleOptions = [
            { label: "Admin", value: "admin" },
            { label: "Author", value: "author" },
            { label: "Editor", value: "editor" },
            { label: "Maintainer", value: "maintainer" },
            { label: "Subscriber", value: "subscriber" },
        ];

        const planOptions = [
            { label: "Basic", value: "basic" },
            { label: "Company", value: "company" },
            { label: "Enterprise", value: "enterprise" },
            { label: "Team", value: "team" },
        ];
        const form = ref(blankForm);

        const USER_APP_STORE_MODULE_NAME = "app-user";

        const { refetchData } = useUsersList();
        // Register module
        if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

        // UnRegister on leave
        onUnmounted(async () => {
            if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME);
        });
        const fakeVal = ref();
        onMounted(async () => {
            if (videoId.value) {
                const resource = await videoRepository.show(videoId.value);
                form.value = resource;
                form.value.file_batch_id = RANDOM_TOKEN;
                fakeVal.value = form.value.fake_played;
                console.log(form);
            }
        });
        const fakeValError = ref();
        watch(
            () => form.value.fake_played,
            (newCount, oldCount) => {
                fakeValError.value = "";
                if (newCount < fakeVal.value) {
                    console.log("its bigger");
                    fakeValError.value = `The Entered Data Shouldn't be Less than ${fakeVal.value} `;
                }
            }
        );
        const resetform = () => {
            form.value = JSON.parse(JSON.stringify(blankForm));
        };
        const onSubmit = async () => {
            if (form.value.thumbnail) {
                delete form.value.thumbnail;
            }
            try {
                const fileRepository = new FileRepository();
                if (router.currentRoute.params.id) {
                    if (fileForm.value.file) {
                        await fileRepository.store(fileForm.value);
                    }
                    await videoRepository.update(form.value.id, form.value);
                    router.push({ name: "apps-videos-list" });
                    console.log(form.value);
                } else {
                    await fileRepository.store(fileForm.value);
                    await videoRepository.store(form.value);
                    router.push({ name: "apps-videos-list" });
                }
            } catch (e) {
                console.log(e);
                return e;
            }
        };
        const { refFormObserver, getValidationState, resetForm } = formValidation(resetform);
        const hasPermission = ref(false);
        const checkPermissions = () => {
            let logedInUserPermissions = JSON.parse(localStorage.getItem("userData")).permissions.map((item) => {
                return item.name;
            });
            if (logedInUserPermissions.includes("Video.Browse.add")) {
                hasPermission.value = true;
            }
        };
        checkPermissions();
        return {
            hasPermission,
            fileForm,
            form,
            refetchData,
            onSubmit,
            videoId,
            refFormObserver,
            getValidationState,
            resetForm,
            avatarText,
            roleOptions,
            planOptions,
            fakeValError,
        };
    },
    watch: {
        "$route.params": function (to, from) {
            this.videoId = to.id;
        },
    },
    created() {
        this.loadGenres();
        this.loadArtists();
    },
    methods: {
        filterGenres(search) {
            debouncedFunction(search, async () => {
                const filters = {
                    name_ar: {
                        type: "like",
                        val: search,
                    },
                };
                const params = {
                    filters,
                    pagination: {
                        page: 1,
                    },
                };
                this.isLoading = true;
                try {
                    this.loadGenres(params);
                } catch (err) {
                    console.log(err, "there was an error loading models");
                } finally {
                    this.isLoading = false;
                }
            });
        },
        async loadGenres(param = {}) {
            const resource = await genreRepository.index(param);
            this.genres = resource.data;
        },
        async loadArtists(params = {}) {
            const resource = await artistRepository.index(params);
            this.artists = resource.data;
        },
        filterArtist(search) {
            debouncedFunction(search, async () => {
                const filters = {
                    name: {
                        type: "like",
                        val: search,
                    },
                };
                const params = {
                    filters,
                    pagination: {
                        page: 1,
                    },
                };
                this.isLoading = true;
                try {
                    this.loadArtists(params);
                } catch (err) {
                    console.log(err, "there was an error loading models");
                } finally {
                    this.isLoading = false;
                }
            });
        },
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
